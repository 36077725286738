import React from "react"
import PropTypes from "prop-types"
import Button from "../../components/button/button"
import Styles from "./pagination.module.scss"

const Pagination = props => {
  const { olderLandingIndex, newerLandingIndex } = props
  const showNewerButton = newerLandingIndex > -1
  const showOlderButton = olderLandingIndex > 0
  return (
    <div className={Styles.pagination}>
      {showOlderButton && (
        <div className={Styles.olderButtonWrapper}>
          <Button link={`/blog/${olderLandingIndex}`}>Older entries</Button>
        </div>
      )}
      {showNewerButton && (
        <div className={Styles.newerButtonWrapper}>
          <Button
            link={newerLandingIndex ? `/blog/${newerLandingIndex}` : "/blog"}
          >
            Newer entries
          </Button>
        </div>
      )}
    </div>
  )
}

Pagination.propTypes = {
  title: PropTypes.string,
}

Pagination.defaultProps = {
  posts: [],
}

export default Pagination
