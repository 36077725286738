import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import Wrapper from "../layout/wrapper/wrapper"
import TextHero from "../../components/text-hero/text-hero"
import BlogPostMeta from "../../components/blog-post-meta/blog-post-meta"
import BlogLatestPosts from "../../components/blog-latest-posts/blog-latest-posts"
import Pagination from "./pagination"
import { Link } from "gatsby"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Image from "../../elements/image/image"
import Styles from "./blog-landing.module.scss"

const BlogLanding = props => {
  const {
    title,
    posts,
    latestPosts,
    olderLandingIndex,
    newerLandingIndex,
  } = props
  const blogPostPath = "/blog-post/"
  const previewLength = 450

  return (
    <>
      <Wrapper>
        <TextHero title={title} />
        <div className={Styles.blogContentWrapper}>
          <div className={Styles.listing}>
            {posts.map(post => {
              const {
                author,
                title,
                _meta,
                body,
                publication_date,
                image,
                imageSharp,
              } = post.node
              const { firstPublicationDate, uid } = _meta
              const publicationTimeStamp =
                publication_date || firstPublicationDate
              const previewText = RichText.asText(
                body,
                linkResolver,
                htmlSerializer
              ).substring(0, previewLength)

              return (
                <Link
                  to={`${blogPostPath}${uid}`}
                  key={uid}
                  className={Styles.article}
                >
                  {image && imageSharp && (
                    <div className={Styles.thumbnailWrapper}>
                      <Image sharp={imageSharp} alt={image.alt} />
                    </div>
                  )}
                  <div>
                    <div
                      to={`${blogPostPath}${uid}`}
                      key={uid}
                      className={Styles.title}
                    >
                      <h2>{title}</h2>
                    </div>

                    <BlogPostMeta author={author} date={publicationTimeStamp} />

                    <p className={Styles.previewText}>{previewText}... </p>

                    <div
                      className={Styles.readMoreLink}
                      to={`${blogPostPath}${uid}`}
                    >
                      Read more
                    </div>
                  </div>
                </Link>
              )
            })}

            <Pagination
              newerLandingIndex={newerLandingIndex}
              olderLandingIndex={olderLandingIndex}
            />
          </div>
          <BlogLatestPosts posts={latestPosts} />
        </div>
      </Wrapper>
    </>
  )
}

BlogLanding.propTypes = {
  title: PropTypes.string,
  newerLandingIndex: PropTypes.number,
  olderLandingIndex: PropTypes.number,
  posts: PropTypes.array,
  latestPosts: PropTypes.array,
}

BlogLanding.defaultProps = {
  posts: [],
}

export default BlogLanding
