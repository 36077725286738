import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Navigation from "../components/navigation/navigation"
import Newsletter from "../components/newsletter/newsletter"
import Lists from "../components/lists/lists"
import BlogLanding from "../components/blog-landing/blog-landing"
import SEO from "../components/seo/seo"

export const query = graphql`
  query BlogLandingQuery {
    prismic {
      allNavigations {
        edges {
          node {
            header {
              ... on PRISMIC_NavigationHeaderMenu {
                primary {
                  title
                  type
                  link {
                    _linkType
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
                fields {
                  link {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                          parent {
                            ... on PRISMIC_Page {
                              title
                              _meta {
                                uid
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  title
                  overview
                }
              }
            }
            footer {
              ... on PRISMIC_NavigationFooterMenu {
                primary {
                  title
                }
                fields {
                  indented
                  link {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                          parent {
                            ... on PRISMIC_Page {
                              title
                              _meta {
                                uid
                              }
                            }
                          }
                        }
                      }
                    }              
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`

const Page = ({ data, pageContext }) => {
  const { header, footer } = data.prismic.allNavigations.edges[0].node
  const {
    metaDescription,
    metaKeywords,
    metaTitle,
    title,
    posts,
    olderLandingIndex,
    newerLandingIndex,
    latestPosts,
  } = pageContext

  return (
    <>
      <SEO
        title={metaTitle || title}
        description={metaDescription || ""}
        keywords={metaKeywords || ""}
      />
      <Layout
        header={
          <Header
            navigation={<Navigation items={header} />}
            showFooterHeader={true}
          />
        }
        body={
          <BlogLanding
            title={title}
            posts={posts}
            olderLandingIndex={olderLandingIndex}
            newerLandingIndex={newerLandingIndex}
            latestPosts={latestPosts}
          />
        }
        footer={
          <Footer
            navigation={<Lists items={footer} />}
            newsletter={<Newsletter />}
          />
        }
      />
    </>
  )
}

export default Page
